document.addEventListener("turbolinks:load", () => {
	const dropdown = document.querySelectorAll('[data-dropdown]');

	dropdown.forEach(el => {
		if (el) {
			let dropdownBtn = el.querySelector('[data-drop-btn]');
			let dropdownContent = el.querySelector('[data-drop-content]');

			dropdownBtn.addEventListener('click', () => {
				dropdownContent.classList.toggle('open-drop');
			}, true);

			document.addEventListener('click', (e) => {
				let target = e.target;
				if (dropdownContent.classList.contains('open-drop') && !target.closest('[data-drop-btn]') && !target.closest('[data-drop-content]')) {
					dropdownContent.classList.remove('open-drop');
				}
			}, true);

			document.addEventListener('scroll', () => {
				window.scrollY > 50 && dropdownContent.classList.contains('open-drop') ? dropdownContent.classList.remove('open-drop') : null;
			});
		}
	});
});
