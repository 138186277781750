//import {nav} from './navigation';

const action = (elements) => {
	elements.forEach((element) => {
		const content = document.getElementById(element.dataset.sticky);
		const nav = document.querySelector('[data-navigation]');
		if (content && element.getBoundingClientRect().height < content.getBoundingClientRect().height) {
			const wrapper = element.parentElement.closest('[data-sticky-wrapper]');
			let inner = wrapper.getBoundingClientRect().top < nav.getBoundingClientRect().height && wrapper.getBoundingClientRect().bottom - element.getBoundingClientRect().height > 130;
			let bottomOuter = wrapper.getBoundingClientRect().bottom - element.getBoundingClientRect().height <= 130;

			if (inner) {
				element.classList.add('sticky');
			} else {
				element.classList.remove('sticky');
			}

			if (bottomOuter) {
				element.classList.add('absolute');
			} else {
				element.classList.remove('absolute');
			}
		}
	});
}

document.addEventListener("turbolinks:load", () => {
	const elements = document.querySelectorAll('[data-sticky]');
	const breakpoint = 599;

	if (elements.length > 0) {
		window.addEventListener('scroll', () => action(elements));
		action(elements);
	}
});
