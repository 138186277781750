document.addEventListener("turbolinks:load", () => {
	let asideAcc = document.querySelectorAll('.project-content__aside-accordion');

	if (!asideAcc) return;

	for (let i=0; i < asideAcc.length; i++) {
		let content = asideAcc[i].querySelector('.project-content__aside-accordion-content');
		let btn = asideAcc[i].querySelector('.project-content__aside-accordion-btn');

		if (content.getBoundingClientRect().height > 48) {
			btn.style.display = 'block';
		} else {
			btn.style.display = 'none';
		}

		btn.addEventListener('click', function () {
			this.classList.toggle('active');
			content.classList.toggle('active');
		});
	}
});
