import {lockScroll, unlockScroll} from './body-scroll';

document.addEventListener("turbolinks:load", () => {
	let btn = document.querySelectorAll('[data-modal]');
	let modal = document.querySelectorAll('.modal');

	let modalBtn = document.querySelectorAll('[data-modal-close]');
	if (btn) {
		let id;

		function closeModal() {

			modal.forEach(modal => {
				modal.classList.remove('open-modal');
				// let modalScrolled = modal.querySelector('.modal');
				unlockScroll('.modal');
			});

		}

		document.addEventListener('click', (event) => {
			let innerTarget = event.target.closest('.modal__inner');
			if (!innerTarget) {
				closeModal();
			}
		}, true);


		modalBtn.forEach(btn => btn.addEventListener('click', closeModal));

		for (let i = 0; i < btn.length; i++) {
			btn[i].addEventListener('click', (ev) => {
				id = btn[i].dataset.modal;
				let modalId = document.getElementById(id);
				modalId.classList.add('open-modal');
				// let modalScrolled = modalId.querySelector('.modal');
				lockScroll('.modal');
			});
		}

	}
});
