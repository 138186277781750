import 'jquery-ui';
import 'jquery-ujs';
import 'select2';

const Turbolinks = require("turbolinks");
Turbolinks.start();

// import 'jquery-ui/i18n/datepicker-ja';
// import 'jquery-ui/widgets/resizable';
// import 'jquery.remotipart';

// Entry point for the build script in your package.json
import './js/core/polyfill';
import {boot} from './js/core/boot';
import {Anchor} from './js/anchor';
import lozad from 'lozad';
import AOS from 'aos';
// import 'aos/dist/aos.css';
import 'simplebar';
// import 'simplebar/dist/simplebar.css';
import './js/body-scroll';
import objectFitImages from 'object-fit-images';
import './js/dropdown';
import './js/slider';
import './js/menu';
import './js/accordion';
import './js/chat';
import './js/modal';
import './js/main';
import './js/sticky';
import './js/navigation';
import './js/change-btn';
import './js/radio-plan';
import './js/show-more';
import './js/secondary-menu';

// for new UI
import './js/plan/ticket'
import './js/payment'

boot.init([
  // module
  { module: Anchor }
]);

document.addEventListener("turbolinks:load", () => {
  AOS.init();
  const observer = lozad();
  observer.observe();
});
objectFitImages();

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

$(document).on('ajax:success', '[data-update]', (event, data) => {
  const dataUpdate = event.target.getAttribute('data-update');

  if (dataUpdate == 'true') {
    event.currentTarget.parentNode.innerHTML = data.html;
  } else {
    document.querySelectorAll(dataUpdate).forEach((el) => el.innerHTML= data.html);
  }
});

$(document).on('ajax:success', '.form-edit-answer', (ev, data) => {
  $(`#answer_notice_${$(ev.target).data('question-id')}`).show();
});

$(document).on('ajax:success', 'form#user_campaign_message_box_form', (ev, data) => {
  $('.chat__body-inner').append(data.html);
  $('form#user_campaign_message_box_form textarea').val('');
});

$(document).on('ajax:success', 'form.new_user_report_comment', (ev, data) => {
  $('.comment__list').prepend(data.html);
  $(ev.target).find('textarea').val(' ');
});

$(document).on('ajax:success', 'form.new_campaign_comment', (ev, data) => {
  $('.comment__list').prepend(data.html);
  $('form.new_campaign_comment textarea').val(' ');
});

$(document).on('ajax:success', 'form.reply-new-campaign-comment', (ev, data) => {
  $(ev.target).parents('.comment__item').append(data.html);
  $(ev.target).hide();
});

$(document).on('ajax:success', '#new_comment_form', (ev, data) => {
  $('#new_comment_form textarea:first').val('');
  $('#new_comment_form button.form-btn').addClass('checked');
});

$(document).on('ajax:success', '.btn-delete-comment', (ev, data) => {
  if ($(ev.target).parents('.comment__answer').length == 0) {
    $(ev.target).parents('.comment__item').remove();
  } else {
    var parent =  $(ev.target).parents('.comment__item')
    $(ev.target).parents('.comment__answer').remove();
    var form = parent.find('form.reply-new-campaign-comment');
    form.show();
    form.find('textarea').val("");
  }
});

$(document).on('ajax:success', 'a.reuse-user-address', (event, data, status, xhr) => {
  location.href = data.redirect_url;
});

document.addEventListener("turbolinks:load", () => {
  if ($('.payment-flow__list').get(0)) {

    $('form.edit_campaign_supporter_plan #campaign_supporter_plan_additional_fee').on('change', (ev) => {
      $(ev.target).parents('form:first').submit();
    });

    $('form.edit_campaign_supporter_plan #campaign_supporter_plan_count').on('change', (ev) => {
      $(ev.target).parents('form:first').submit();
    });
  }

  $(document).on('ajax:error', 'form.form-ajax', (event, xhr, status, error) => {
    const errorsList = $('.flash-error');
    errorsList.find('li').remove();
    if (!xhr.responseJSON) return;

    xhr.responseJSON.errors.map((message) => {
      errorsList.find('ul').append("<li class='error'>" + message + "</li>");
      if (message) {
        errorsList.addClass('active');
      } else {
        errorsList.removeClass('active');
      }
    });
  });

  $(document).on('ajax:error', 'form.form-signin', (event, xhr, status, error) => {
    const errorsList = $(event.target).find('.flash-error');
    errorsList.find('li').remove();
    if (!xhr.responseText) return;

    errorsList.find('ul').append("<li class='error'>" + xhr.responseText + "</li>");
    errorsList.addClass('active');
  });

  $(document).on('blur', '.submit-on-blur', (ev, data) => {
    $(ev.target).parents('form').find('input[type=submit]').submit();
  });
  $(document).on('change', '.submit-on-change', (ev, data) => {
    $(ev.target).parents('form').find('input[type=submit]').submit();
  });

  $(document).on('ajax:success', 'form.form-payment-supporter', (event, data, status, xhr) => {
    if(data.redirect_url){
      location.href = data.redirect_url;
    }
  });

  $('form#new_user').on('ajax:success', (event, data, status, xhr) => {
    location.href = data.redirect_url;
  }).on('ajax:error', (event, xhr, status, error) => {
    const errors = xhr.responseJSON.errors;
    $('span.error-message').remove();
    if (errors['recaptcha']){
      $('#recaptcha-input').append(`<span class='error-message'>${errors['recaptcha']}</span>`);
      grecaptcha.reset();
      return;
    }
    for (const [key, values] of Object.entries(errors)) {
      values.map((value) => {
        let target = document.getElementsByName(`user[${key}]`)[0];
        if (target) {
          $(target).parents('.form__group').append(`<span class='error-message'>${value}</span>`);
        } else {
          $(document.getElementById(`${key}_id`)).append(`<span class='error-message'>${value}</span>`);
        }
      });
    }
    if (window.grecaptcha){
      grecaptcha.reset();
    }
    window.scrollTo({top: 0})
  });

  $('input[type=file]').on('change', (ev) => {
    const file = $(ev.target).prop('files')[0];
    $(ev.target).parents('.form-file-field').find('p.text').text(file.name);
  });

  $('.project-visual__slide-img__movie-start a').on('click', (ev) => {
    const slide = $(ev.target).parents('.project-visual__slide-img');
    slide.find('img.project-visual__slide-img__img').hide();
    $(slide.find('iframe')).show().css('width', $(slide).outerWidth() + 'px').css('height', $(slide).outerHeight() + 'px');
    $(ev.target).parents('.project-visual__slide-img__movie-start').hide();
  });

  $('.project-visual__slider-thumbs .swiper-slide').map((_, el) => {
    $(el).on('click', (ev) => {
      const slide = $('.project-visual__slide-img');
      slide.find('img.project-visual__slide-img__img').show();
      $(slide.find('iframe')).hide();
      $('.project-visual__slide-img__movie-start').show();
    });
  });

  const body = document.querySelector('.project-content__body-inner');
  if (body) {
    $(body).find('h2').map((_, el) => {
      $(el).attr('id', $(el).text().replace(/\n/, ''));
      if ($(el).next() && $(el).next().find('img').length > 0 && $(el).next().text() == '') {
        $(el).css('height', 0).css('overflow', 'hidden');
      }
    });

    const links = document.querySelectorAll('.project-content__aside-inner a.project-content__aside-link');
    links.forEach((link) => {
      $(document).on('click', link, (ev) => {
        if ($(ev.target).attr('href')) {
          const des = document.getElementById($(ev.target).attr('href').replace(/^#/, ''));
          if (des) {
            window.scroll({
              top: des.offsetTop + $('.project-visual').height() + 150,
              behavior: "smooth"
            })
            return false;
          }
        }
      });
    });

    if (location.hash) {
      setTimeout(() => {
      const hash = location.hash;
      const des = document.getElementById(decodeURI(hash.replace(/^#/, '')));
      if (!des) return;
      window.scroll({
        top: des.offsetTop + $('.project-visual').height() + 150,
        behavior: "smooth"
      })
      return false;
    }, 1000);
    }
  }
});