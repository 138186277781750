document.addEventListener("turbolinks:load", () => {
	const nav = document.querySelector('[data-navigation]');
	const navDestroy = document.querySelector('[data-navigation-destroy]');
	const footer = document.querySelector('.footer');

	if (nav) {
		const navContent = nav.querySelector('[data-navigation-content]');
		const breakpoint = 599;

		fixedNav();
		window.addEventListener('scroll', () => fixedNav());

		function fixedNav() {
			if (innerWidth < breakpoint && navDestroy) return;
			if (nav.getBoundingClientRect().top < 0) {
				navContent.classList.add('fixed');
			} else {
				navContent.classList.remove('fixed');
			}
			if (footer.getBoundingClientRect().top < 0) {
				navContent.classList.remove('fixed');
			}
		}
	}
});
