document.addEventListener("turbolinks:load", () => {
  $('.form-aution-payment-supporter').on('submit', function(ev) {
    $(ev.target).find('button[type=submit]').attr({ disabled: 'disabled' });
    $('.error-box').addClass('hide');
    var shop_id = $('#new_campaign_supporter').find("#payment_shop_id").val();
    Multipayment.init(shop_id);
    var cardno = $('#campaign_supporter_payment_card_no').val();
    var expire =  $('#campaign_supporter_payment_expire_year').val() + $('#campaign_supporter_payment_expire_month').val();
    var securitycode = $('#campaign_supporter_payment_security_code').val();
    Multipayment.getToken({
      cardno: cardno,
      expire: expire,
      securitycode: securitycode
    }, function(res) {
      if (res.resultCode == '000' && res.tokenObject.isSecurityCodeSet) {
        $('.error-box').addClass('hide');
        $('#campaign_supporter_payment_token').val(res.tokenObject.token);
        $('#new_campaign_supporter').submit();
      } else {
        var error = '';
        if (res.resultCode == '000') {
          error = 'セキュリティーコードを確認してください';
        } else if (res.resultCode < 110) {
          error = 'カード番号を確認してください';
        } else if (res.resultCode < 120) {
          error = '有効期限を確認してください';
        } else if (res.resultCode < 120) {
          error = 'セキュリティーコードを確認してください';
        } else {
          error = '情報を再度確認してください';
        }
        $('.error-box').html(error);
        $('.error-box').removeClass('hide');
        $('.error-box').show()
        $(ev.target).find('button[type=submit]').removeAttr('disabled');
      }
    });
    return false;
  });
  $(document).on('ajax:error', '#new_campaign_supporter', (event, xhr) => {
    const errorsList =  $('.error-box');
    $('form.form-payment-supporter button[type=submit]').removeAttr('disabled');

    if (!xhr.responseText) return;
    error_str = ""
    xhr.responseJSON.errors.map(error => {
      error_str += error + '</br>'
    });
    errorsList.html(error_str);
    errorsList.removeClass('hide')
  });
})