import Swiper, {Navigation, Pagination, Autoplay, Thumbs} from 'swiper';
// import Swiper and modules styles
// TODO: Comment out by Kaito Wings
// import 'swiper/swiper-bundle.min.css';
// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay, Thumbs]);

document.addEventListener("turbolinks:load", () => {
	// init Swiper:
	document.querySelectorAll('[data-card-slider]').forEach((scope) => {
		new Swiper(
			scope.querySelector('.swiper'),
			{
				spaceBetween: 20,
				slidesPerView: 1.3,
				navigation: {
					nextEl: scope.querySelector('.swiper-button-next'),
					prevEl: scope.querySelector('.swiper-button-prev'),
				},
				breakpoints: {
					1024: {
						slidesPerView: 3.4,
						spaceBetween: 30,
					},
					600: {
						slidesPerView: 2.4,
					},
				},
			}
		);
	});

	document.querySelectorAll('[data-form-slider]').forEach((scope) => {
		new Swiper(
			scope.querySelector('.swiper'),
			{
				spaceBetween: 14,
				slidesPerView: 1.5,
				navigation: {
					nextEl: scope.querySelector('.swiper-button-next__wrap'),
					prevEl: scope.querySelector('.swiper-button-prev__wrap'),
				},

				breakpoints: {
					1200: {
						slidesPerView: 4,
						spaceBetween: 30,

					},
					600: {
						slidesPerView: 3,

					},
				},
			}
		);
	});

	// init Swiper-top:
	const swiperTop = new Swiper('.top-visual__swiper', {
		spaceBetween: 30,
		slidesPerView: 1,
		autoplay: {
			delay: 6000,
			disableOnInteraction: false,
		},
		navigation: {
			nextEl: '.top-visual__swiper-button-next',
			prevEl: '.top-visual__swiper-button-prev',
		},
		pagination: {
			el: '.top-visual__swiper-pagination',
			type: 'fraction',
			formatFractionCurrent: function (number) {
				if (number < 10) {
					number = '0' + number;
				}
				return number;
			},
			formatFractionTotal: function (number) {
				if (number < 10) {
					number = '0' + number;
				}
				return number;
			},
		},
	});

	// thumbnails
	var swiperThumbs = new Swiper('.project-visual__slider-thumbs', {
		spaceBetween: 10,
		slidesPerView: 5,
		freeMode: true,
		watchSlidesProgress: true,
		watchOverflow: true,
		breakpoints: {
			600: {
				slidesPerView: 6.4,
			},
		},
	});
	var swiperProject = new Swiper('.project-visual__slider', {
		spaceBetween: 7,
		navigation: {
			nextEl: '.project-visual__swiper-button-next',
			prevEl: '.project-visual__swiper-button-prev',
		},
		thumbs: {
			swiper: swiperThumbs,
		},
		watchOverflow: true,
		breakpoints: {
			600: {
				spaceBetween: 12,
			},
		},
	});

	swiperProject.on('slideChange', () => {
		document.querySelectorAll('.project-visual__slider iframe').forEach((el) => {
			el.contentWindow.postMessage('{"event":"command", "func":"pauseVideo", "args":""}', '*');
		});
	});

	(function () {
		let slideWrapper = document.querySelector('.project-visual__slider-wrap');
		let slideThumbWrapper = document.querySelector('.project-visual__slider-thumbs');
		let slide = document.querySelectorAll('.project-visual__slider .swiper-slide');

		if(slide.length == 1) {
			slideWrapper.classList.add('slider-disable');
			slideThumbWrapper.classList.add('slider-disable');
		}
		
	})();

	// aside swiper
	document.querySelectorAll('.project-content__aside-slider').forEach((scope) => {
		new Swiper(
			scope.querySelector('.project-content__aside-swiper'),
			{
				spaceBetween: 20,
				slidesPerView: 1,
				navigation: {
					nextEl: '.project-content__aside-button-next',
					prevEl: '.project-content__aside-button-prev',
				},
				pagination: {
					el: '.project-content__aside-pagination',
					clickable: true,
					type: 'bullets',
				},
			}
		);
	});
});
