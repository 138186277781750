import {lockScroll, unlockScroll} from './body-scroll';

document.addEventListener("turbolinks:load", () => {
	const header = document.getElementById('header');
	const scrolledElement = document.querySelectorAll('[data-scrolletd]');
	const btn = document.querySelectorAll('[data-btn-menu]');
	const closeBtn = document.querySelectorAll('[data-close-btn]');
	let id = null;

  btn.forEach((el) => {

		el.addEventListener('click', (e) => {
			e.preventDefault();
			id = el.getAttribute('data-btn-menu');
			openMenu(id);
		});

		function openMenu(id) {
			closeMenu();
			let scrolledTargetEl = document.querySelector(`#${id} [data-scrolletd]`);
			lockScroll(scrolledTargetEl);
			scrolledTargetEl.querySelectorAll('a').forEach((el) => {
				el.addEventListener('click', () => {
					unlockScroll(scrolledTargetEl);
				})
			});
			if (id === 'login-menu') {
				header.classList.add('menu-open');
			}
			document.getElementById(`${id}`).classList.add('menu-open');
		}
	});

	closeBtn.forEach(el => {
		el.addEventListener('click', (e) => {
			e.preventDefault();
			closeMenu();
		});
	});

	function closeMenu() {
		document.querySelectorAll('.menu-open').forEach(el => el.classList.remove('menu-open'));
		scrolledElement.forEach(el => {
			unlockScroll(el);
		});
	}
});