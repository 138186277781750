document.addEventListener('turbolinks:load', () => {
  setTimeout(() => {
		const chatBody = document.querySelectorAll('.chat__body');
		chatBody.forEach(el => {
			const chatBodyContent = el.querySelector('.chat__body-inner');
			const scrolledElement = el.querySelector('.simplebar-content-wrapper');
			let heightChatBodyContent = chatBodyContent.getBoundingClientRect().height;
			scrolledElement.scrollTo(0, heightChatBodyContent);
		});
	},500);
});
