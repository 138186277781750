function labelSignsNumber() {
	let labels = document.querySelectorAll('.label');
	let wordCount = 8;
	labels.forEach(label => {
		let text = label.innerText.trim();
		if (text.length > wordCount) {
			let newText = text.substring(0, wordCount);
			label.innerText = `${newText}...`;
		}
	});
}

labelSignsNumber();
