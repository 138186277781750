(function () {
	let radioForm = document.querySelector('[data-plan-form]');
	let radioItem = document.querySelectorAll('[data-plan-item]');

	if (!radioForm) return;

	radioForm.addEventListener('input', function () {
		for (let i = 0; i < radioItem.length; i++) {
			let radioInput = radioItem[i].querySelector('[data-plan-input]');
			let radioLabel = radioItem[i].querySelector('[data-plan-label]');

			if (radioInput.checked) {
				radioLabel.innerHTML = radioLabel.dataset.planLabel;
			} else {
				radioLabel.innerHTML = radioLabel.dataset.defaultLabel;
			}
		}
	});
})();
