import {lockScroll, unlockScroll} from './body-scroll';

document.addEventListener("turbolinks:load", () => {
	(function secondaryMenu() {
		let btnOpen = document.querySelector('[data-secondary-btn]');
		let menu = document.querySelector('[data-secondary-body]');
		let btnClose = document.querySelector('[data-secondary-close]');
		let scrolledEl = document.querySelector('.secondary-menu__parent-list');
		let state = false;

		if (!btnOpen) return;

		btnOpen.addEventListener('click', ()=> {
			if (state === false) {
				openMenu();
			} else {
				closeMenu();
			}
		});

		btnClose.addEventListener('click', ()=> {
			closeMenu();
		});

		function openMenu() {
			lockScroll(scrolledEl);
			state = true;
			menu.classList.add('open');
			scrolledEl.querySelectorAll('a').forEach((el) => {
				el.addEventListener('click', () => {
					unlockScroll(scrolledEl);
				})
			});
		}

		function closeMenu() {
			unlockScroll(scrolledEl);
			state = false;
			menu.classList.remove('open');
		}
	})();

	(function secondaryFixed() {
		let btnWrap = document.querySelector('[data-secondary]');
		let btn = document.querySelector('[data-secondary-fixed]');
		const footer = document.querySelector('.footer');
		const breakpoint = 599;

		if (!btnWrap) return;

		window.onload = () => secondaryAction();
		window.addEventListener('scroll', () => secondaryAction());

		function secondaryAction() {
			if (innerWidth > breakpoint) return;
			if (btnWrap.getBoundingClientRect().top < 0) {
				btn.classList.add('fixed');
			} else {
				btn.classList.remove('fixed');
			}
			if (footer.getBoundingClientRect().top < 0) {
				btn.classList.remove('fixed');
			}
		}
	})();
});