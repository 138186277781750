document.addEventListener("turbolinks:load", () => {
  $(document).ready(function(){
    $('form#new_campaign_plan_payment_ticket').on('ajax:success', (event, data) => {
      location.href = data.redirect_url
    }).on('ajax:error', (event, xhr, status, error) => {
      console.log( xhr.responseJSON.errors)
      $('.flash-error ul').html('')
      if(!xhr.responseJSON.errors){
        return;

      }
      const errorsList =  $('.error-box');
      error_str = ""
      xhr.responseJSON.errors.map(error => {
        error_str += error + '</br>'
      });
      errorsList.html(error_str);
      errorsList.removeClass('hide')

      $('html, body').animate({ scrollTop: $('.error-box').offset().top }, 500)
    })
  })

})
