document.addEventListener('turbolinks:load', () => {
	const accordion = document.querySelectorAll('[data-accordion]');
	const accordionBtn = document.querySelectorAll('[data-accordion-btn]');

	if (accordionBtn) {
		function openDrop(i) {
			let height = accordion[i].scrollHeight;
			accordion[i].style.height = `${height}px`;
			accordion[i].classList.add('open');
			accordionBtn[i].classList.add('active');
			accordion[i].dataset.accordion = 'open';
		}

		function closeDrop(i) {
			accordion[i].style.height = '';
			accordion[i].classList.remove('open');
			accordionBtn[i].classList.remove('active');
			accordion[i].dataset.accordion = 'close';
		}

		for (let i = 0; i < accordionBtn.length; i++) {
			if (accordion[i].dataset.accordion === 'close') {
				closeDrop(i);

			} else {
				openDrop(i);
			}

			accordionBtn[i].addEventListener('click', () => {
				if (accordion[i].classList.contains('open')) {
					closeDrop(i);

				} else {
					openDrop(i);
				}
			});
		}
	}
});
